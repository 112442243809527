<template>
<div>
    <h1>dsdfsdsdffds</h1>
</div>
</template>

<script>
    export default {
        name: "HomeComponent"
    }
</script>

<style scoped>

</style>
